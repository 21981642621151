import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';

export default class GiftedChatComponent extends Component {
    static propTypes = {
        messages: PropTypes.array,
        onSend: PropTypes.func.isRequired,
        onLoadMessages: PropTypes.func.isRequired,
        profile: PropTypes.shape({
            id: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        }),
        giftedChatProps: PropTypes.object,
    };
    static defaultProps = {
        messages: [],
        giftedChatProps: {},
    };
    state = {
        messages: [],
        isInitialized: false,
        GiftedChatComp: null,
    };

    componentDidMount = () => {
        const { messages } = this.props;
        this.setState({
            isInitialized: true,
            messages: messages.map(this.mapMessage),
            GiftedChatComp: require('react-web-gifted-chat').GiftedChat,
        });
    }

    componentDidUpdate = prevProps => {
        const { messages } = this.props;
        const { isInitialized } = this.state;

        if(isInitialized && JSON.stringify(prevProps.messages) !== JSON.stringify(messages)) {
            this.setState({ messages: messages.map(this.mapMessage) });
        }
    }

    onSend = (messages = []) => {
        const { onSend } = this.props;
        const { GiftedChatComp } = this.state;

        messages = messages.filter(message => message.text);
        if(!messages.length) {
            return null;
        }

        this.setState(previousState => ({
            messages: GiftedChatComp.append(previousState.messages, messages),
        }), () => {
            onSend(messages);
        });
    }

    mapMessage = message => (
        console.log(message), {
        ...message,
        id: message.id,
        text: message.message || message.text,
        createdAt: message.createdAt,
        user: {
            id: message.senderId,
            name: message.senderId,
            avatar: message.sender?.avatar,
        },
    })

    render() {
        const { profile, onLoadMessages, giftedChatProps } = this.props;
        const { GiftedChatComp, messages } = this.state;

        return (
            <StyledComponent styles={require('./styles')}>
                {GiftedChatComp &&
                    <GiftedChatComp
                        messages={messages}
                        onSend={this.onSend}
                        user={{
                            id: profile.id,
                            name: profile.email,
                        }}
                        locale="pl"
                        placeholder="Wpisz wiadomość"
                        loadEarlier={true}
                        onLoadEarlier={onLoadMessages}
                        {...giftedChatProps}
                        showUserAvatar={true}
                    />
                }
            </StyledComponent>
        );
    }
}
