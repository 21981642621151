import React from 'react';
import PropTypes from 'prop-types';
import StyledComponent from 'Components/core/StyledComponent';

import ErrorMessage from 'Components/forms/ErrorMessage';
import InputLabel from 'Components/forms/InputLabel';

export default class FormSelectComponent extends React.Component {
    static displayName = 'FormSelectComponent';
    static propTypes = {
        name: PropTypes.string,
        onChange: PropTypes.func,
        onFormChange: PropTypes.func,
        icon: PropTypes.shape({
            type: PropTypes.oneOf(['fa', 'path']).isRequired,
            source: PropTypes.string.isRequired,
        }),
        background: PropTypes.string,
        label: PropTypes.string,
        errorMessage: PropTypes.string,
        options: PropTypes.array,
        value: PropTypes.string,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        className: PropTypes.string,
        defaultValue: PropTypes.string,
    };
    static defaultProps = {
        icon: null,
        label: '',
        errorMessage: '',
        value: '',
        required: false,
        disabled: false,
        background: '',
        className: '',
        defaultValue: '',
    }

    getIcon = icon => {
        switch (icon.type) {
            case 'fa':
                return (<i className={icon.source} />);
            case 'path':
                return (<img src={icon.source} />);
        }
    }

    onChange = event => {
        const { onChange, onFormChange } = this.props;
        const data = {
            name: event.target.name,
            value: event.target.value,
        };

        if (onChange) {
            onChange(data);
        }

        if (onFormChange) {
            onFormChange(data);
        }

        return data;
    }

    render() {
        const { value, name, icon, label, errorMessage, required, options, disabled, background, className, defaultValue } = this.props;

        const selectedDefaultValue = options.length
            ? options[0].value
            : defaultValue;

        const selectProps = {
            id: name,
            name,
            onChange: this.onChange,
            value: value || selectedDefaultValue,
            required,
            disabled,
        };

        return (
            <StyledComponent styles={require('./styles')} className={`form-select-component ${className}`}>
                {label && <InputLabel label={label} />}
                <select {...selectProps} className={`${background ? 'select-background' : ''}`}>
                    {options ? options.map(option => (
                        <option
                            value={option.value}
                            label={option.label}
                            key={option.value}
                        >
                            {option.label}
                        </option>
                    )) : null
                    }
                </select>
                {icon && <div className="select-icon">{this.getIcon(icon)}</div>}
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </StyledComponent>
        );
    }
}
