import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        width: 100%;
        display: flex;
        position: relative;
        flex-direction: column;

        select {
            cursor: pointer;
            font-weight: 700;
            outline: none;
            font-size: 1em;
            height: 4em;
            padding: 0 1.7em;
            background-color: #FFFFFF;
            border: none;
            width: 100%;
            text-align: center;
            font-weight: 400;
            border-radius: .3em;
        }

        #form-input-label {
            margin: .2em .5em;
            span {
                font-size: 1em;
                font-weight: 300;
                white-space: nowrap;
            }
        }

        .select-icon {
            position: absolute;
            top: 50%;
            font-size: 1.2em;
            transform: translate(100%, -50%);
        }

        #form-error-message-component {
            text-align: left;
            margin-top: .2em;
            margin-left: 1em;
        }

    `;
