import { css } from '@emotion/core';
import { relative } from 'path';

export default (props, state) =>
    css({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',

        '.conversation-header': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: 'white',
            width: '100%',
            padding: '1em',

            '.conversation-users': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',

                '.conversation-user': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    margin: '.5em',

                    '.user-avatar': {
                        width: '5em',
                        height: '5em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '36em',
                        overflow: 'hidden',
                    },
                    '.user-details': {
                        marginLeft: '1em',

                        '.user-role': {
                            fontSize: '.9em',
                            fontWeight: '600',
                            color: '#666666',
                        },
                        '.user-name': {
                            fontSize: '1.1em',
                            fontWeight: '600',
                        },
                        '.user-email': {
                            fontSize: '.9em',
                            color: '#666666',
                        },
                    },
                },
            },
        },

        '.conversation-chat': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            height: '100%',
            position: 'relative',
            paddingTop: '1em',

            '.no-result': {
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: 'absolute',
            },

            '> div': {
                height: '100%',
                width: '100%',
            },

        },
    });
