import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import GiftedChat from 'Components/layoutAdmin/GiftedChat';
import Spinner from 'Components/layoutAdmin/Spinner';
import NoResult from 'Components/layoutAdmin/NoResult';

export default class ChatConversationWindow extends Component {
    static propTypes = {
        conversation: PropTypes.shape({
            id: PropTypes.string.isRequired,
            users: PropTypes.array.isRequired,
            role: PropTypes.string.isRequired,
        }),
        messages: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired,
        onSend: PropTypes.func.isRequired,
        onLoadEarlierMessages: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
    };
    static defaultProps = {
        readOnly: false,
        conversation: {
            id: null,
            users: [],
            role: null,
        },
    };

    render() {
        const { conversation, isLoading, messages, onSend, onLoadEarlierMessages, readOnly } = this.props;

        let giftedChatProps = {};
        if(readOnly) {
            giftedChatProps = {
                ...giftedChatProps,
                renderInputToolbar: () => null,
            };
        }

        return (
            <StyledComponent className="chat-converstion-window" styles={require('./styles')}>
                <div className="conversation-header">
                    <div className="conversation-users">
                        {conversation.users.map(user => (
                            <div className="conversation-user" key={user.id}>
                                <div className="user-avatar">
                                    <img className="avatar-image" src={user?.avatar || require('Theme/images/placeholders/image.jpg')} />
                                </div>
                                <div className="user-details">
                                    <p className="user-role">
                                        {user.role}
                                    </p>
                                    <h4 className="user-name">
                                        {user.name} {user.surname}
                                    </h4>
                                    <p className="user-email">
                                        {user.email}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="conversation-chat">
                    {isLoading && <Spinner />}
                    {messages.length === 0 &&
                        <NoResult />
                    }
                    <GiftedChat
                        messages={messages || []}
                        onSend={onSend}
                        onLoadMessages={onLoadEarlierMessages}
                        giftedChatProps={giftedChatProps}
                    />
                </div>
            </StyledComponent>
        );
    }
}
